<template>
  <div>
    <PageIntro
      title="Artworks"
      :show-sort-filter="true"
      :sort-items="sortItems"
      @setSort="setSort"
    />

    <div id="stack" class="section">
      <div class="wrapper">
        <div class="filters-trigger" @click="isActive = !isActive">Filters</div>

        <div class="filters" :class="{ active: isActive }">
          <div class="svg close" @click="isActive = false">
            <svg><use xlink:href="#svg-close" /></svg>
          </div>

          <div class="filters-wrapper">
            <h2>Filters</h2>

            <PriceRangeFilter
              ref="priceRangeFilter"
              @setPrice="resetPrevLoading"
              :disable-price-btn="loading"
            />
            <CurrencyFilter
              :loading="loading"
              :currencies="currencies"
              @change="makeCurrencyFilter"
              ref="CurrencyFilter"
            />
            <TokenStatusFilter
              :loading="loading"
              :statuses="statuses"
              @change="makeStatusFilter"
              ref="TokenStatusFilter"
            />
            <TokenTypeFilter
              :loading="loading"
              :types="types"
              @change="makeTypeFilter"
              ref="TokenTypeFilter"
            />

            <button @click="resetFilter" :disable="loading">
              Reset Filters
            </button>
          </div>
        </div>

        <div class="tiles">
          <div class="flex">
            <TokenCard
              v-for="(item, index) in items"
              :key="index"
              :token="item"
            />
          </div>
          <ScrollLoader
            :loader-method="getPublicNftTokensByFilter"
            :loader-disable="disableLoading"
            ><img
              v-show="loading"
              width="200"
              height="200"
              src="@/assets/img/preloader.svg"
            />
          </ScrollLoader>
          <NoRecordsFound v-if="!items.length && !loading" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageIntro from "../components/common/PageIntro";
import PriceRangeFilter from "../components/filters/PriceRangeFilter";
import TokenStatusFilter from "../components/filters/TokenStatusFilter";
import TokenTypeFilter from "../components/filters/TokenTypeFilter";
import TokenCard from "../components/common/TokenCard";
import api from "@/api/api";
import resetPrevLoadingMixin from "@/mixins/resetPrevLoadingMixin";
import setSortMixin from "@/mixins/setSortMixin";
import checkRefreshPageMixin from "@/mixins/checkRefreshPageMixin";
import NoRecordsFound from "@/components/common/NoRecordsFound";
import { mapGetters, mapMutations } from "vuex";
import CurrencyFilter from "@/components/filters/CurrencyFilter";

export default {
  name: "Artworks",
  mixins: [resetPrevLoadingMixin, setSortMixin, checkRefreshPageMixin],
  data: () => ({
    isActive: false,
    page: 0,
    size: 48,
    total: 0,
    loading: false,
    sort: "created,desc",
    items: [],
    disableLoading: false,
    statuses: [
      {
        text: "No auction",
        value: "NO_AUCTION",
      },
      {
        text: "Fixed price",
        value: "ON_SIMPLE_AUCTION",
      },
      {
        text: "Time auction",
        value: "ON_TIME_AUCTION",
      },
    ],
    currencies: [
      {
        text: "Eth",
        value: "ETH",
      },
      {
        text: "Matic",
        value: "MATIC",
      },
    ],
    types: ["Image", "Gif", "Video", "Audio"],
    sortItems: [
      {
        text: "Date minted to oldest",
        value: "created,desc",
      },
      {
        text: "Date minted to newest",
        value: "created,asc",
      },
      {
        text: "USD price to more expensive",
        value: "currentUsdtPrice,asc",
      },
      {
        text: "USD price to more cheaper",
        value: "currentUsdtPrice,desc",
      },
    ],
    selectedStatuses: [],
    selectedCurrencies: [],
    selectedTypes: [],
  }),
  computed: {
    ...mapGetters({
      getSocketToken: "marketplace/getSocketToken",
      getUpdatedTokensLength: "marketplace/getUpdatedTokensLength",
      getUpdatedTokens: "marketplace/getUpdatedTokens",
    }),
  },
  watch: {
    getUpdatedTokensLength(length) {
      let foundIndex = this.items.findIndex(
        (x) => x.id === this.getUpdatedTokens[length - 1].id
      );
      if (foundIndex === -1) {
        return;
      }
      let items = [...this.items];
      items[foundIndex] = this.getUpdatedTokens[length - 1];
      this.items = [...items];
      this.items[foundIndex] = this.getUpdatedTokens[length - 1];
    },
    getSocketToken(val) {
      if (val) {
        let isTokenExists = !!this.items.find((i) => i.id === val.id);
        if (isTokenExists) {
          return;
        }
        this.items.unshift(val);
        this.setToken(null);
      }
    },
    $route() {
      this.checkRefreshPage();
    },
  },
  methods: {
    ...mapMutations({
      setToken: "marketplace/setToken",
    }),
    resetFilter() {
      this.$refs.CurrencyFilter.reset();
      this.$refs.priceRangeFilter.reset();
      this.$refs.TokenStatusFilter.reset();
      this.$refs.TokenTypeFilter.reset();
      this.selectedStatuses = [];
      this.selectedTypes = [];
      this.selectedCurrencies = [];
      this.resetPrevLoading();
      this.scrollToPop();
    },
    makeCurrencyFilter(val) {
      let item = this.currencies.find((i) => i.text === val.property);
      if (val.value) {
        this.selectedCurrencies.push(item.value);
      } else {
        this.selectedCurrencies = this.selectedCurrencies.filter(
          (i) => i !== item.value
        );
      }
      this.resetPrevLoading();
      this.scrollToPop();
    },
    makeStatusFilter(val) {
      let item = this.statuses.find((i) => i.text === val.property);
      if (val.value) {
        this.selectedStatuses.push(item.value);
      } else {
        this.selectedStatuses = this.selectedStatuses.filter(
          (i) => i !== item.value
        );
      }
      this.resetPrevLoading();
      this.scrollToPop();
    },
    makeTypeFilter(val) {
      if (val.value) {
        this.selectedTypes.push(val.property.toUpperCase());
      } else {
        this.selectedTypes = this.selectedTypes.filter(
          (i) => i !== val.property.toUpperCase()
        );
      }
      this.resetPrevLoading();
      this.scrollToPop();
    },
    scrollToPop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    async getPublicNftTokensByFilter() {
      try {
        this.loading = true;
        const body = {
          priceFrom: this.$refs.priceRangeFilter.priceFrom,
          priceTo: this.$refs.priceRangeFilter.priceTo,
          statuses: this.selectedStatuses,
          types: this.selectedTypes,
          currencies: this.selectedCurrencies,
        };
        const query = {
          page: this.page++,
          size: this.size,
          sort: this.sort,
        };
        const response = await api.getPublicNftTokensByFilter({
          body,
          query,
        });
        if (this.page === 0) {
          this.items = [];
        }
        this.items = [...this.items, ...response.content];
        this.disableLoading = response.content.length < this.size;
        this.total = response.totalElements;
      } catch {
        this.disableLoading = true;
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 500);
      }
    },
  },
  components: {
    CurrencyFilter,
    NoRecordsFound,
    PageIntro,
    PriceRangeFilter,
    TokenStatusFilter,
    TokenTypeFilter,
    TokenCard,
  },
};
</script>

<template>
  <div class="filter-item">
    <div class="fel">
      <div class="fel-label">Price range</div>
      <div class="fel-range">
        <div class="flex">
          <input
            type="number"
            name="min"
            min="0"
            placeholder="$0.00"
            step="0.000000001"
            v-model="priceFrom"
          />
          <input
            type="number"
            name="max"
            min="0"
            placeholder="$0.00"
            step="0.000000001"
            v-model="priceTo"
          />
        </div>
        <button class="green" @click="setPrice" :disabled="disablePriceBtn">Apply</button>
        <!-- @submit.prevent="setPrice" -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import errors from "@/util/errors";

export default {
  name: "PriceRangeFilter",
  props: {
    disablePriceBtn: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    priceFrom: null,
    priceTo: null,
  }),
  methods: {
    ...mapMutations({
      setError: "general/setError",
    }),
    reset() {
      this.priceTo = null;
      this.priceFrom = null;
    },
    setPrice() {
      if (!this.priceFrom && !this.priceTo) {
        return;
      }
      if (this.priceFrom < 0 || this.priceTo < 0) {
        this.setError(errors.NEGATIVE_PRICE);
        return;
      }
      this.$emit("setPrice");
    },
  },
};
</script>

<template>
  <div class="filter-item">
    <div class="fel">
      <div class="fel-label">Status</div>
      <FilterMainCheckBox
        v-for="(item, index) in statuses"
        :key="index"
        :checkbox-name="item.text"
        @change="change"
        :loading="loading"
        :ref="`status-${index}`"
      />
    </div>
  </div>
</template>

<script>
import FilterMainCheckBox from "@/components/filters/filtersParts/FilterMainCheckbox";
export default {
  name: "TokenStatusFilter",
  components: { FilterMainCheckBox },
  props: {
    statuses: {
      type: Array,
      required: true,
    },
    loading: {
      required: false,
      default: () => false,
    },
  },
  methods: {
    change(val) {
      this.$emit("change", val);
    },
    reset() {
      let iterations = this.statuses.length - 1;
      while (iterations >= 0) {
        this.$refs[`status-${iterations}`][0].reset();
        iterations--;
      }
    },
  },
};
</script>

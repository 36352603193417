import { mapGetters, mapMutations } from "vuex";

export default {
  computed: {
    ...mapGetters({
      getNeedRefresh: "general/getNeedRefresh",
    }),
  },
  methods: {
    ...mapMutations({
      setNeedRefresh: "general/setNeedRefresh",
    }),
    checkRefreshPage() {
      if (this.$route.name === this.getNeedRefresh) {
        this.resetPrevLoading();
        this.setNeedRefresh(false);
      }
    },
  },
};
